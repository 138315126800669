import * as React from 'react'

import { useMobileSize } from '@/utils/browser/resize-hook'
import { getSizeBy375 } from '@/utils/style/size'

import StyledSkeleton from './styled'

type Props = {
  style?: React.CSSProperties
  pcWidth?: string | number
  mobileWidth?: string | number
  pcHeight?: string | number
  mobileHeight?: string | number
  loading?: boolean
  className?: string
  borderRadius?: string | number
  children?: React.ReactNode
}

/**
사용하는 부모 컴포넌트에서 크기를 지정해 줄 수 있도록 여러 설계를 제공
1. props로 크기 지정 가능
2. 부모 컴포넌트의 styled에서 --skeleton 클래스 이름으로 크기 지정 가능
*/

const Skeleton = ({
  loading = false,
  style = {},
  pcWidth,
  pcHeight,
  mobileWidth,
  mobileHeight,
  borderRadius,
  className,
  children,
}: Props) => {
  const { isMobile } = useMobileSize()

  const layoutStyle = React.useMemo(() => {
    if (isMobile) {
      const width = mobileWidth ?? (typeof pcWidth === 'number' ? getSizeBy375(pcWidth) : pcWidth)
      const height =
        mobileHeight ?? (typeof pcHeight === 'number' ? getSizeBy375(pcHeight) : pcHeight)
      return { width, height, borderRadius }
    }

    return { width: pcWidth, height: pcHeight, borderRadius }
  }, [borderRadius, isMobile, mobileHeight, mobileWidth, pcHeight, pcWidth])

  return loading || children === undefined || children === null ? (
    <StyledSkeleton
      className={`--skeleton${className ? ` ${className}` : ''}`}
      style={{ ...layoutStyle, ...style }}
    />
  ) : (
    <>{children}</>
  )
}

export default React.memo(Skeleton)
