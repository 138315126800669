const createGetSizeByWidth = ({ width }: { width: number }) => {
  return (pixel: number, unit = 'vw') => {
    return `${(pixel / width) * 100}${unit}`
  }
}

export const getSizeBy375 = createGetSizeByWidth({ width: 375 })

export const getSizeBy1920 = createGetSizeByWidth({ width: 1920 })

export function px2vwValue(px: number): number {
  const value = px / 3.75

  if (Number.isNaN(value)) {
    throw new Error(`NaN returned with param -> ${px}.`)
  }

  return value
}

export function px2vw2Value(px: number, viewportWidth: number): number {
  const value = (px / viewportWidth) * 100

  if (Number.isNaN(value)) {
    throw new Error(`NaN returned with param -> ${px}.`)
  }

  return value
}

export function px2vw(px: number, viewportWidth?: number): string {
  return viewportWidth ? `${px2vw2Value(px, viewportWidth)}vw` : `${px2vwValue(px)}vw`
}
